import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../main_background.png";
import logo from '../logo.png';
import login from '../login-logo1.png';


function goApp() {

	window.open("https://app.burnerpc.com");


};


function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};




	return (<>
	






	<div className='navBarBox' >  




	<Flex
	direction="row"
	
	alignItems='center'
>

<Flex
	direction="row"
	
	alignItems='center'
	paddingTop='8px'
>

 <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">
 <img src={logo} alt="Burner PC"  />
</Link> 

<span hidden><Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link></span>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/faq">  FAQ</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/help">  Help</Link>
<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/about">  About</Link>


</Flex>

<Flex
	direction="row"
	grow="1"
	
>

</Flex>

<Flex
	direction="row"
	alignItems='center'
	
	paddingTop='10px'
>

<a className="navBarEnd"
          href="https://app.burnerpc.com"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >
	 <img src={login} alt="Burner PC" height={35} width={35} />

</a>

</Flex>
</Flex>




</div>


<div className='pageContent'>

<div style={{  opacity: '.99' }}>
{// backgroundImage: `url(${background})`
}

	
	
	
	
	
<Flex
		direction="column"
		
	minWidth='99vw'
	paddingLeft='1vw'
	
		//opacity='.90'
		gap='0px'
		position='fixed'
	paddingTop='20px'
	justifyContent="center"	
    alignItems='center'
	alignContent='center'
	>


		<Flex
		
		
		>

		     <span  className='pageContentTitle' >BURNER PC</span>

		</Flex>

		
	



	</Flex>
	







<br></br>


<Flex
			direction="column"
			
			textAlign="center"
			//gap="2.5vh"
			
			fontSize='14px'
					paddingTop='calc(80px + 2vw)'
			
		>





<div className='helpContent' style={{textAlign:'center' , backgroundColor:'black' ,zIndex:'2'  }} >


	






	
<Flex
	direction='row'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	position='sticky'
	width="96vw"
	
	gap='0'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	
	width="4vw"
	
		
	paddingTop='0px'
	paddingBottom='15px'
	>
		


	</Flex>


	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	
	width="94vw"
	
			marginLeft='25px'
		marginRight='25px'
	paddingTop='0px'
	paddingBottom='15px'
	>






<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='black'
	borderRadius='25px'
		>


		<span className='pageContentSmall'   >ABOUT
	</span>












			
	<span  className='AboutContent' >	Contact: <a style={{ color: "white" , textDecoration: 'none' }} href="mailto:support@burnerpc.com">support@burnerpc.com</a> </span> 
			   
			   <span  className='AboutContent' >Copyright © 2024 BurnerPC Project - Comburent.io LLC - All Rights Reserved. </span> 
				












			   </Flex>

	</Flex>


	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"

	grow='1'
	
	
	paddingTop='0px'
	paddingBottom='15px'
	>
		


	</Flex>




	</Flex>





	



</div>

		</Flex>




	

</div>


	</div>

 </>
		
	
	)
}

export default Home;
