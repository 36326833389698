import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../main_background.png";
import logo from '../logo.png';
import login from '../login-logo1.png';
import imgA from "./help1.png";
import imgB from "./help2.png";

function goApp() {

	window.open("https://app.burnerpc.com");


};


function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};




	return (<>
	

	<div className='navBarBox' >  

	<Flex
	direction="row"
	
	alignItems='center'
>

<Flex
	direction="row"
	
	alignItems='center'
	paddingTop='8px'
>

 <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">
 <img src={logo} alt="Burner PC"  />
</Link> 

<span hidden><Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link></span>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/faq">  FAQ</Link>
<Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/help">  Help</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">  About</Link>


</Flex>

<Flex
	direction="row"
	grow="1"
	
>

</Flex>

<Flex
	direction="row"
	alignItems='center'
	
	paddingTop='10px'
>

<a className="navBarEnd"
          href="https://app.burnerpc.com"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >
	 <img src={login} alt="Burner PC" height={35} width={35} />

</a>

</Flex>
</Flex>

</div>


<div className='pageContent'>

<div style={{ 	  opacity: '.99'  }}>
{// backgroundImage: `url(${background})`
}

	
	
<Flex
		direction="column"
		
	minWidth='99vw'
	paddingLeft='1vw'
	
		//opacity='.90'
		gap='0px'
		position='fixed'
	paddingTop='20px'
	justifyContent="center"	
    alignItems='center'
	alignContent='center'
	>


		<Flex
		
		
		>

		     <span  className='pageContentTitle' >BURNER PC</span>

		</Flex>

		
	



	</Flex>
	











<br></br>



<Flex
			direction="row"
			
			textAlign="center"
			//gap="2.5vh"
				
			fontSize='14px'
					paddingTop='calc(80px + 2vw)'
		
		>


<div style = {{zIndex:'1' }}>


















<div className='helpContent' style={{textAlign:'center'  }} >

	







	
<Flex
	direction='row'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	position='sticky'
	width="96vw"
	
	gap='0'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	
	width="4vw"
	
		
	paddingTop='0px'
	paddingBottom='15px'
	>
		


	</Flex>


	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	
	width="94vw"
	
		
	paddingTop='0px'
	paddingBottom='15px'
	>





<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='black'
	borderRadius='50px'
		>



		<span className='pageContentSmall'  >HELP
	</span>
















	<Flex 
direction="column"
justifyContent="center"
alignItems="center"
textAlign='left'
paddingLeft='5px'
paddingRight='5px' 
marginLeft='10px'
marginRight='10px' 

>

<span >



<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
>
<span className = 'helpContentHedder'>GENERAL USE</span></Flex>
<br></br>
-To create and start a new Cloud PC, use the CREATE PC button on the BurnerPC Portal.<br></br>
-Buy more time via PayPal with the ADD TIME button.<br></br>
-To shut down and permanently erase the Cloud PC, use the DESTROY PC button on the BurnerPC Portal.<br></br>
-It normally takes 10-15 seconds for the Cloud PC to become available after using the CREATE PC Button.<br></br>  
-During peak service usage the Cloud PC start time can occasionally increase up to 2min. This effect is temporary.<br></br>
-Your paid time is only consumed while the Cloud PC is the RUNNING state.<br></br>
-The BurnerPC Portal does not need to be left open to complete the destruction (or creation) process once it has begun.<br></br>
-You must SIGN OUT when finished if you want the BurnerPC Portal to ask for your username/password the next time you open it.

<br></br>
<br></br>
</span>
<span >

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>ADDITIONAL FEATURES</span></Flex>
<br></br>
-SMS Time Alerts: To enable or disable SMS Alerts - while Cloud PC is STOPPED, use the OPTION Button and then the SMS ALERTS Button.<br></br>
-Auto Shut Down Time: To modify the Auto Shut Down Time - while Cloud PC is RUNNING, use the OPTION Button and then the SD TIME Button.<br></br>
-Copy & Paste: Files can be transferred by using the 'copy' command on the Cloud PC and then the 'paste' command on your Local PC.<br></br>
-Help Chatbot:  To SHOW or HIDE the Help Chatbot, use the OPTION Button and then the SHOW CHATBOT or HIDE CHATBOT Button.
<br></br>
<br></br>
</span>
<span >

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>ADDITIONAL DETAILS</span></Flex>
<br></br>
-Only one BurnerPC can be active per account at a time.  You can use multiple BurnerPCs if you create multiple accounts.<br></br>
-Purchased time does not expire and is consumed in 1 min increments while the Cloud PC is RUNNING.<br></br>
-If your Time Bank is over 2,000 minutes, purchasing additional time will be disabled until you are under the limit.
<br></br>
<br></br>
</span>
<span >

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>MAC SPECIFIC INSTRUCTIONS</span></Flex>
<br></br>
-Before connecting for the first time please download and install 'Microsoft Remote Desktop' from the Apple App Store.<br></br>
-Once installed proceed to the FIRST TIME CONNECTION section.
<br></br>
<br></br>
</span>
<span >

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>PC SPECIFIC INSTRUCTIONS</span></Flex>
<br></br>
-Windows 10 and later is already ready to go! Proceed to the FIRST TIME CONNECTION section.
<br></br>
<br></br>
</span>
<span >

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"><span className = 'helpContentHedder'>FIRST TIME CONNECTION / RDP CONNECT HELP</span></Flex>
<br></br>
-Once the Cloud PC is up and RUNNING, the RDP CONNECT Button will appear.<br></br>
-Clicking this button will bring up an RDP INFO Pop-Up displaying the current IP Address, User Name, and Password for your Cloud PC.<br></br>
-Next Click the 'Download' button to DOWNLOAD an RDP Connection Shortcut file.<br></br>
-Once received, use the 'COPY' button on the RDP INFO Pop-Up to store the current password to the clipboard.<br></br>
-Next open the RDP Shortcut file to automatically launch the 'Remote Desktop Connection' app with the IP Address and User Name already filled out.<br></br>
-NOTE: Some browsers will alert on the RDP Shortcut file, however it is only a plain text file that contains the current login information.<br></br>
-The 'Remote Desktop Connection' app will start, alert you about the connection and ask if you want to continue.  Click 'Connect' to proceed.<br></br>
-You will be prompted for the password from RDP INFO Pop-Up, enter it into the password box (use CTRL-V to PASTE) and click 'OK' to proceed.<br></br>
-A final message will appear, warning about the identity and certificate of the remote computer.  Click "Yes" to proceed. <br></br>
-NOTE: This is not an error as new certificates are generated with each new Cloud PC
<br></br>
<br></br>
</span>
<span >

<Flex 

textAlign='center'
justifyContent="center"
alignItems="center"
><span className = 'helpContentHedder'>CONTACT SUPPORT</span></Flex>
<span>
<br></br>
If you require additional help we encourage you to try the Help ChatBot or contact BurnerPC E-mail Support directly at: <a style={{textAlign:'left', color: 'yellow', textDecoration: 'none' }} href="mailto:support@burnerpc.com">support@burnerpc.com</a>
<br></br>
<br></br>
</span>

</span>
</Flex>











<span className = 'helpContentHedder' style={{ fontSize:'23px'}}>PORTAL LAYOUT GUIDE</span>

<div className = 'imageBorder'>
<Flex 

direction="row"
justifyContent="center"
alignItems="center"
textAlign="center"
//marginLeft='3vw'
//marginRight='3vw'
paddingBottom="10px"
wrap='wrap'
gap="40px"
color='inherit'
>

<Image
	
	src={imgA}
	style={{ width: '380px' , borderColor: 'inherit', borderStyle: 'solid', borderWidth: '4px' }}
	
  />


<Image
	
	src={imgB}
	style={{ width: '380px', borderColor: 'inherit', borderStyle: 'solid', borderWidth: '4px'  }}
  />

</Flex>

</div>










</Flex>


	</Flex>


	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"

	grow='1'
	
	
	paddingTop='0px'
	paddingBottom='15px'
	>
		


	</Flex>




	</Flex>








	






<br></br>

</div>



















		</div>


		</Flex>





</div>


	</div>

 </>
		
	
	)
}

export default Home;
