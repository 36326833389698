import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.


import "./App.css";
import "@aws-amplify/ui-react/styles.css";
//import { API, Storage } from "aws-amplify";
import {
 Flex,
//  Heading,
  Image,
//  View,
} from "@aws-amplify/ui-react";
//import { listNotes } from "./graphql/queries";
import { Routes, Route, Link } from 'react-router-dom';
import Home from './component/home';
import About from './component/about';
import Tos from './component/tos';
import Help from './component/help';
import Faq from './component/faq';


import background from "./b11b.png";



function App() {
	const [init, setInit] = useState(false);
	useEffect(() => {
		document.title = 'BurnerPC - Secure Temporary Computing';

		initParticlesEngine(async (engine) => {
			// you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
			// this loads the tsparticles package bundle, it's the easiest method for getting everything ready
			// starting from v2 you can add only the features you need reducing the bundle size
			//await loadAll(engine);
			//await loadFull(engine);
			await loadSlim(engine);
			//await loadBasic(engine);
		  }).then(() => {
			setInit(true);
		  });



	  }, []);


	  const particlesLoaded = (container) => {
		console.log(container);
	  };
	



	  const options = useMemo(
		() => ({
		  background: {
			color: {
			  value: "#000000",
			},
		  },
		  fpsLimit: 120,
		  interactivity: {
			events: {
			  onClick: {
				enable: true,
				mode: "push",
			  },
			  onHover: {
				enable: true,
				mode: "repulse",
			  },
			},
			modes: {
			  push: {
				quantity: 4,
			  },
			  repulse: {
				distance: 200,
				duration: 0.4,
			  },
			},
		  },
		  particles: {
			color: {
			  value: "#ff4500",
			},
			links: {
			  color: "#ffffff",
			  distance: 150,
			  enable: false,
			  opacity: 0.5,
			  width: 1,
			},
			move: {
			  direction: "none",
			  enable: true,
			  outModes: {
				default: "bounce",
			  },
			  random: false,
			  speed: 3,
			  straight: false,
			},
			number: {
			  density: {
				enable: true,
			  },
			  value: 70,
			},
			opacity: {
			  value: 0.5,
			},
			shape: {
			  type: "circle",
			},
			size: {
			  value: { min: 1, max: 5 },
			},
		  },
		  detectRetina: true,
		}),
		[],
	  );



if (init) {
  
  return ( 
  
  <div className="App"  >
 
    <Particles id="tsparticles" particlesLoaded={particlesLoaded} options={options} />;
 

      

     
		  <div className = 'Body'   >


    
   
			
	
			
		
		<Routes>
				<Route exact path='/' element={< Home />}></Route>
				<Route exact path='/about' element={< About />}></Route>
				<Route path='/tos' element={< Tos />}></Route>
				<Route path='/help' element={< Help />}></Route>
				<Route path='/faq' element={< Faq />}></Route>
				<Route path="*" element={<NotFound />}></Route>
		</Routes>
		
	

	
		</div>

		


		
	

		
		</div>
	
      
		


  );
};

}

export const NotFound = () => {
	return <div>This is a 404 page</div>
  }

//export default withAuthenticator(App);
export default App;