import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../main_background.png";





function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};
 var tmpgap = '0rem';


 function goApp() {

	window.open("https://app.burnerpc.com");


};


	return (<>
	

	<div className='navBarBox' >  

	<Flex
		direction="row"
		alignItems='center'
	>
	

<Flex
	direction="row"
	justifyContent="center"	
		alignItems='center'
>

<span> <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">BPC</Link> </span>
<span><Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link></span>
<span><Link className='navBarLink' style={{ textDecoration: 'none' }} to="/faq">  FAQ</Link></span>
<span><Link className='navBarLink' style={{ textDecoration: 'none' }} to="/help">  Help</Link></span>
<span><Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">  About</Link></span>


</Flex>

<Flex
	direction="row"
	grow="1"
>

</Flex>

<Flex
	direction="row"
	justifyContent="center"	
>

<span>
<a className="navBarEnd"
          href="https://app.burnerpc.com"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >LG
</a>
</span>	

</Flex>


</Flex>	

</div>


<div className='pageContent'>

<div style={{ color: 'inherit' , height: '20%', opacity: '1',backgroundColor: 'inherit' , backgroundImage: `url(${background})`,  backgroundRepeat:"no-repeat",backgroundPosition:"center top",backgroundSize:"cover" ,backgroundAttachment: 'fixed'  }}>
{// backgroundImage: `url(${background})`
}

	
	
	
	
	
	
	<Flex
		direction="column"
		justifyContent="center"
		alignItems="center"
		textAlign="center"
		marginLeft='3vw'
		marginRight='3vw'
		paddingTop="1vh"
		paddingBottom="2.5vh"
		fontSize="calc(38px + 2vh)"
		//opacity='.90'
		gap='0px'
	>
		<span className = 'Top-title'  >Welcome to BurnerPC</span>
	<span className='pageContentMed2'>Early Access is Live!   	
		<button className = "App-link-button" style={{ marginLeft: "14px" , paddingLeft: '10px', paddingRight: '10px',  fontWeight: "", fontSize: "calc(5px + 2vh)", height: "auto", width: "auto" }} onClick={goApp}>Sign Up / Login Here</button>
		</span>
	
	</Flex>
	

	</div>

	<div className = "Mid-bar">
	<br></br>
	
	
	<Flex 
	color='inherit' 
	fontSize='calc(15px + 2vh)'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	//opacity='.85'
	><span style={{ paddingLeft: '20px', paddingRight: '20px', textDecorationThickness: '1px'  }}>Browse the Internet safely and discreetly with a disposable Cloud PC
	</span>
	</Flex>

<br></br>
</div>











<br></br>

<Flex
			direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			//gap="2.5vh"
			marginTop="2.5vh"
			fontSize='14px'
			
		>



<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='calc(15px + 2vh)'		
			color='inherit'	
			//opacity='.85'
			>
		
		<span className = 'pageContentHedder'>BURNER PC FEATURES:</span>
		
		</Flex>
		
		<Flex
			direction="row"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
			gap="0"
			fontSize='28px'
			marginTop="1.5vh"
		>

		<span className = "boxBorder2">
		<Flex
			direction="column"
			justifyContent="center"
			alignItems="left"
			textAlign="left"
			//gap="0"
			
			gap="calc(55px - 6vw)"
			//marginLeft='0px5
			//marginRight='0px'
			wrap='wrap'
			fontSize='calc(5px + 2vh)'
			//boxShadow='inset 0 0 0 1px'
			paddingLeft="2.5vh"
			//paddingRight="8px"
			//backgroundColor='dark grey'
			//color='inherit'	
			//opacity='.85'
		
		>
		<span> Remotely interact with websites in a protected and anonymous way.</span>
		<span> Get a fresh, newly created, disposable Cloud PC with each startup.</span>
        <span> Cloud PCs are encrypted while in use and completely destroyed on shutdown.</span>
        <span> No more browsing history, tracking cookies or cached files to deal with.</span>
        <span> Malicious sites do not have access to the data on your PC through the Cloud PC.</span>
        <span> Files deemed safe can be transferred back to your PC if needed.</span>
		<span> Datacenters are located in the US West and US East regions.</span>
		<span> Compatible with PC, Mac, iOS, Android and Linux.</span>
		<span> Cloud PC Specifications - 2x 2.5GHz vCPU, 4GB RAM - $0.39/hour</span>

		</Flex>
		</span>	


		</Flex>




		


		




		</Flex>


<br></br>






	</div>

 </>
		
	
	)
}

export default Home;
