import React from 'react';
//import logo from './s1iwy.JPG';
import {
 Flex,
  Heading,
Image,
//  View,
} from "@aws-amplify/ui-react";
import { Link } from 'react-router-dom';
//import background from "../background3.jpeg";
import background from "../main_background.png";
import logo from '../logo.png';
import login from '../login-logo1.png';


function goApp() {

	window.open("https://app.burnerpc.com");


};


function Home (){

 // if ((window.matchMedia("(orientation: portrait)").matches)) {var tmpgap = '1rem'} else {tmpgap = '0rem'};




	return (<>
	

	<div className='navBarBox' >  



<Flex
	direction="row"
	backgroundColor='black'
	alignItems='center'
>

<Flex
	direction="row"
	
	alignItems='center'
	paddingTop='8px'
>

 <Link className = "navBarTitle" style={{ textDecoration: 'none' }} to="/">
 <img src={logo} alt="Burner PC"  />
</Link> 

<span hidden><Link className='navBarLinkActive' style={{ textDecoration: 'none' }} to="/">  Home</Link></span>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/faq">  FAQ</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/help">  Help</Link>
<Link className='navBarLink' style={{ textDecoration: 'none' }} to="/about">  About</Link>


</Flex>

<Flex
	direction="row"
	grow="1"
	
>

</Flex>

<Flex
	direction="row"
	alignItems='center'
	
	paddingTop='10px'
>

<a className="navBarEnd"
          href="https://app.burnerpc.com"
          target="_blank"
          rel="noopener noreferrer"
		  style={{ textDecoration: 'none' }}
        >
	 <img src={login} alt="Burner PC" height={35} width={35} />

</a>

</Flex>
</Flex>
</div>






<div className='pageContent'>

<div style={{ 	opacity:'.99'  }}>
{// backgroundImage: `url(${background})`
}

	
	
	
	
	
	
	<Flex
		direction="column"
		
	minWidth='99vw'
	paddingLeft='1vw'
	
		//opacity='.90'
		gap='0px'
		position='fixed'
	paddingTop='20px'
	justifyContent="center"	
    alignItems='center'
	alignContent='center'
	
	>


		<Flex
		
		
		>

		     <span  className='pageContentTitle' >BURNER PC</span>
		
		</Flex>

		
	



	</Flex>
	













<br></br>












<Flex
			direction="column"
	
			textAlign="center"
			//gap="2.5vh"
			
			fontSize='14px'
			paddingTop='calc(80px + 2vw)'
			
		>






<div className='helpContent' style={{textAlign:'center',  zIndex:'2'  }} >







	
<Flex
	direction='row'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	position='sticky'
	width="96vw"
	
	gap='0'
>




<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	
	width="4vw"
	
		
	paddingTop='0px'
	paddingBottom='15px'
	>
		


	</Flex>


	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"

	width="94vw"
	
		
	paddingTop='0px'
	paddingBottom='15px'
	>






<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	
	
		>















<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='black'
		marginLeft='calc(40px - 2vw)'
		marginRight='calc(40px - 2vw)'
	borderRadius='25px'	

		>
















<Flex 
		direction="column"
			justifyContent="center"
			alignItems="center"
			textAlign="center"
		
	
		
		borderRadius='50px'
		
		>
		<span className='pageContentMed2'  >Browse the Internet safely and discreetly with a disposable Cloud PC
	</span>
	</Flex>









	<Flex 
	wrap='wrap'
	direction='row'
	justifyContent="center"
	alignItems="center"
	textAlign="center"

			paddingTop='20px'

	gap='calc(2vw + 2px)'
	
	>





<Flex 
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='#ce1515'
	width='calc(260px + 4vw)'
	minHeight='calc(13vw + 45px)'
	fontSize='calc(10px + 1vw)'
	borderRadius='5px'
	
	>
		<span style={{ margin: '10px' }}>
Get a fresh, newly created Burner PC with each startup!
</span>
</Flex>

<Flex 
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='#e49b13'
	width='calc(260px + 4vw)'
	minHeight='calc(13vw + 45px)'
	fontSize='calc(10px + 1vw)'
	color='black'
	borderRadius='5px'
	>
		<span style={{ margin: '10px' }}>
No more browsing history, tracking cookies or cached files to deal with!
</span>
</Flex>

<Flex 
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='#ce1515'
	width='calc(260px + 4vw)'
	minHeight='calc(13vw + 45px)'
	fontSize='calc(10px + 1vw)'
	borderRadius='5px'
	
	>
		<span style={{ margin: '10px' }}>
Burner PCs are encrypted while in use and destroyed on shutdown!
</span>
</Flex>

<Flex 
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	backgroundColor='#e49b13'
	width='calc(260px + 4vw)'
		
	minHeight='calc(13vw + 45px)'
	color='black'
		fontSize='calc(10px + 1vw)'
		borderRadius='5px'
	>
		<span style={{ margin: '10px' }}>
Malicious sites do not have access to your data through the Burner PC!
</span>
</Flex>






</Flex>



















		</Flex>


















		<Flex 
	
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
		
			
			gap='0px'
>


<Flex 
	
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"
	paddingTop='10px'
	paddingBottom='20px'
	gap='1vw'
	
	>

	<span className='pageContentMed2'  style={{height:'auto' , borderRadius:'25px',  backgroundColor:'black'}} >CLOSED BETA is Live!  </span>

		<button className = "App-link-button" style={{borderRadius:'5px',  border: 'none', marginTop: "14px", backgroundColor: "#ce1515" , paddingLeft:'15px', paddingRight:'15px' , paddingTop:'2px', paddingBottom:'6px',  color:'white', opacity:'1',  fontWeight: "", fontSize: "calc(15px + 1vw)", height: "auto", width: "auto" }} onClick={goApp}>Sign Up Here</button>
		

		</Flex>


		</Flex>































		</Flex>


	</Flex>


	<Flex 
	direction='column'
	justifyContent="center"
	alignItems="center"
	textAlign="center"

	grow='1'
	
	backgroundColor='yellow'
	paddingTop='0px'
	paddingBottom='15px'
	>
		


	</Flex>




	</Flex>









	</div>


		</Flex>




























		



</div>


	</div>

 </>
		
	
	)
}

export default Home;
